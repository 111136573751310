<template>
  <v-container>
    <v-row dense>
      <v-col cols="4" v-for="nav in navigation" v-bind:key="nav.id">
        <v-card
          class="mx-auto justify-center text-h5"
          color="#003A40"
          v-on:click="goto(nav.goal)"
          dark
        >
          <v-avatar class="ma-2" size="100" tile>
            <v-img :src="getImage(nav.image)"></v-img>
          </v-avatar>

          <v-card-title class="text-h6" white v-text="nav.name"> </v-card-title>
          <v-card-subtitle v-text="nav.beschreibung"></v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MainNavigation",
  props: {},
  data() {
    return {
      navigation: [
        {
          id: 1,
          name: "Architekturbeschreibung",
          goal: "old/architekturerfassung",
          image: "architektur_uebersicht.png",
          beschreibung: "Beschreiben oder Betrachten Sie die Architektur eines Projektes"
        },
        {
          id: 2,
          name: "Berichte",
          goal: "old/berichteuebersicht",
          image: "berichte.png",
          beschreibung: "Allgemein verfügbare Berichte über Produkte und Prozesse"
        },
      ],
      items: [
        {
          text: "Dashboard",
          disabled: true,
          href: "main"
        }
      ]
    };
  },
  methods: {
    goto(path) {
      try {
        this.$router.push({ path: "/" + path });
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    },
    logout() {
      try {
        this.$store.dispatch("logout");
        this.$router.push({ path: "/" });
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    },
    getImage(filename) {
      return require("@/assets/" + filename);
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.getUser;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center {
  margin: auto;
  width: 70%;
  padding: 10px;
  text-align: center;
}
</style>
